import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useUserStore from "../../../services/userStore";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { myLocalStorage } from "../../../components/StorageHelper";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import AzureDeviceItem from "./Components/AzureDeviceItem";
import SyncIcon from "@mui/icons-material/Sync";

const AzureDevices = () => {
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [tenantList, setTenantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState(null);

  const demandSync = async () => {
    const response = await axios.get(`${NGROK}/api/azure-sync/onDemand`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };
  const fetchAzureDevices = async (isFirst = false) => {
    try {
      if (isFirst) {
        setLoading(true);
      }
      const response = await axios.get(
        `${NGROK}/api/azure/v1/devices?tenantName=${selectedTenantName}`,
      );
      if (response?.data) {
        setDevices(response?.data);
      }
    } catch (e) {}

    setLoading(false);
  };
  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);
  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);
  useEffect(() => {
    if (!selectedTenantName) return;
    fetchAzureDevices(true);
    const interval = setInterval(() => {
      fetchAzureDevices();
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedTenantName]);
  return (
    <>
      <Box p={2}>
        <Stack direction={"column"} spacing={5} mb={3}>
          <Typography variant="h5">Azure Devices</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {tenantList?.length > 0 && (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={selectedTenantName ? selectedTenantName : " "}
                  options={tenantList.map((tenant) => tenant.tenantName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tenant List" />
                  )}
                  onChange={(e, value) => {
                    handleTenantChange(value);
                  }}
                />
              )}
            </Box>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
              onClick={() => {
                demandSync();
              }}
            >
              <SyncIcon />
              Sync
            </Button>
          </Box>
        </Stack>
        <TableContainer component={Paper}>
          <Table
            sx={{
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <MyHeaderTableCell
                  sx={{ fontSize: 18 }}
                  align={"center"}
                  className={"userName"}
                >
                  Devices
                </MyHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices?.length > 0 ? (
                devices.map((device, index) => (
                  <TableRow className="groupRow" key={index}>
                    <TableCell
                      component="td"
                      scope="row"
                      className="groupRowName userName"
                    >
                      <AzureDeviceItem device={device} />
                    </TableCell>
                  </TableRow>
                ))
              ) : loading ? (
                <TableRow className="groupRow">
                  <TableCell
                    component="td"
                    scope="row"
                    className="groupRowName userName"
                    align="center"
                    colSpan={5}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow className="groupRow">
                  <TableCell
                    component="td"
                    scope="row"
                    className="groupRowName userName"
                    align="center"
                    colSpan={5}
                  >
                    <Typography variant="body1">No devices found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AzureDevices;
